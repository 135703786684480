@import 'Variables';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font: 1rem 'Montserrat', sans-serif;
  font-size: 16px;
  color: $white;
}

.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, $primary-color 30%, $secondary-color 30%);
  bottom: 0;
  left: -50%;
  opacity: .5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 4s;
}

.bg.bg3 {
  animation-duration: 5s;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

// HEADER START
.navbar .nav-item {
  margin: .5rem 1rem;
  position: relative;
  .nav-link::before {
    content: "";
    width: 0;
    transition: width .3s;
    border-radius: 7px;
  }
  .nav-link.active::before, 
  .nav-link:hover::before,
  .nav-link:focus::before {
    content: "";
    width: 18px;
    background: $primary-color;
    height: 3px;
    position: absolute;
    bottom: 0;
    transition: width .3s;
  }
}

// HEADER END

// ELSE START
.DomainSearch {
  margin: 15rem 0 20rem 0;
}

.section-page {
  padding: 10rem 0;
}

.section-page.bg-light, section.bg-white {
  * {
    color: $dark !important;
  }
}

.custom-v {
  font-size: 1.7rem;
  font-weight: 700;
}

.custom-hr {
  margin: 1px 0;
  height: 2px !important;
  background: $primary-color;
  opacity: 1;
  border-radius: 7px;
}

.side-text {
  font-size: .8rem;
}

.bg-custom {
  background-color: $secondary-color;
}

.text-white {
  color: $white !important;
}

.text-dark {
  color: $dark !important;
}
// ELSE END

// FOOTER START
footer {
  background-color: $white;
  width: 100%;
  * {
    color: $dark;
  }
}
// FOOTER END